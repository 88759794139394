import React, { useEffect, useState } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import authConfig from '../authConfig';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const msalInstance = new PublicClientApplication(authConfig);

const LoginButton = ({ setIsLoggedIn, setUserInfo, setTableauToken }) => {
    const [isMsalInitialized, setMsalInitialized] = useState(false);
    const navigate = useNavigate();

    const acquireTableauToken = async () => {
        try {
            const msalToken = await msalInstance.acquireTokenSilent({
                scopes: ['User.Read'],
            });

            const response = await axios.post('https://10ay.online.tableau.com/api/3.10/auth/signin', {
                credentials: {
                    token: msalToken.accessToken,
                },
            });

            return response.data.token;
        } catch (error) {
            console.error('Error acquiring Tableau token', error);
            return null;
        }
    };

    useEffect(() => {
        const initializeMsal = async () => {
            try {
                await msalInstance.initialize(); // Wait for initialization
                setMsalInitialized(true);
                
                const response = await msalInstance.handleRedirectPromise();
                if (response && response.account) {
                    setIsLoggedIn(true);
                    const roles = response.account.idTokenClaims.roles || [];
                    const userWithRoles = { ...response.account, roles };
                    setUserInfo(userWithRoles);
                    localStorage.setItem('userInfo', JSON.stringify(userWithRoles));

                    // Now that MSAL is initialized, acquire the Tableau token
                    const tableauToken = await acquireTableauToken();
                    setTableauToken(tableauToken);

                    // Redirect to HomePage after successful login
                    navigate('/');
                }
            } catch (error) {
                console.error('MSAL initialization failed', error);
            }
        };

        initializeMsal();
    }, [setIsLoggedIn, setUserInfo, setTableauToken, navigate]);

    const handleLogin = () => {
        msalInstance.loginRedirect({
            scopes: ['User.Read'],
        });
    };

    return (
        <div className="wrapper-container">
            {/* Wrapper for the left and right containers */}
            <div className="side-by-side-container">
                {/* Left container */}
                <div className="left-container">
                    <h2>Ability to automate data capture and reporting of blood culture contamination performance</h2>
                </div>
    
                {/* Right container */}
                <div className="right-container">
                    <h2>Generate actionable insights to facilitate continuous quality and clinical performance improvement</h2>
                </div>

                {/* Other container */}
                <div className="other-container">
                    <h2>Detailed, facility-level analysis of clinical and patient impact</h2>
                </div>
            </div>
    
            {/* Button container */}
            <div style={sectionStyle}>
                <h1>Login Page</h1>
                <button
                    className="App-button"
                    onClick={handleLogin}
                    disabled={!isMsalInitialized} // Disable until initialized
                >
                    Sign in
                </button>
                <h2>Want to register?</h2>
                <button
                    className="App-button"
                >
                    Register
                </button>
            </div>
        </div>
    );
};

// Transparent border style for the sections
const sectionStyle = {
    marginTop: '30px',
    padding: '20px',
    border: '3px solid rgba(0, 0, 0, 0.2)',  // Transparent black border
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',  // Optional light shadow for effect
};

export default LoginButton;