import React, { useEffect } from 'react';

const TableauDashboard = () => {
    useEffect(() => {
        const containerDiv = document.getElementById('tableauViz');
        const url = 'https://10ay.online.tableau.com/t/magnoliamedicalmetrics/views/MMTAnalyticsDemo/MMTAnalytics?:embed=y'; // Update with your Tableau URL
        const options = {
            width: containerDiv.offsetWidth,
            height: containerDiv.offsetHeight,
            hideTabs: true,
            hideToolbar: true,
            onFirstInteractive: function () {
                console.log('Tableau Dashboard is ready.');
            }
        };

        // Dispose of any existing viz before creating a new one
        const existingViz = containerDiv.__tableauViz;
        if (existingViz) {
            existingViz.dispose();
        }

        // Create a new viz
        const viz = new window.tableau.Viz(containerDiv, url, options);
        containerDiv.__tableauViz = viz; // Store reference for later disposal

        return () => {
            // Clean up on component unmount
            if (viz) {
                viz.dispose();
            }
        };
    }, []);

    return (
        <div>
            <h2>Dashboard</h2>
            <div id="tableauViz" style={{ width: '100%', height: '800px' }}></div>
        </div>
    );
};

export default TableauDashboard;