import React, { useState } from 'react';
import { BlobServiceClient } from '@azure/storage-blob';
import * as XLSX from 'xlsx';  // Import the xlsx library

const FileUploadPage = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [filePreview, setFilePreview] = useState(null);
  const [fileType, setFileType] = useState(null); // To store the file type
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open state

  const storageAccountName = 'marwadata';
  const containerName = 'map';
  const sasToken = 'sp=racwl&st=2024-12-10T21:41:42Z&se=2025-12-11T05:41:42Z&spr=https&sv=2022-11-02&sr=c&sig=hkf%2Blr73gfauZ7QFhzpiXbeSil83AYE9YPmS7KFbpyg%3D'; // Ensure SAS token is valid

  // Initialize BlobServiceClient
  const containerClient = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
  ).getContainerClient(containerName);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileType(selectedFile.type);

    // Handle preview based on file type
    if (selectedFile) {
      if (selectedFile.type.startsWith('image/')) {
        // Preview for image files
        const reader = new FileReader();
        reader.onloadend = () => {
          setFilePreview(reader.result);
        };
        reader.readAsDataURL(selectedFile);
      } else if (selectedFile.type === 'application/pdf') {
        // Preview for PDF files
        setFilePreview(URL.createObjectURL(selectedFile));
      } else if (selectedFile.type.startsWith('text/')) {
        // Preview for text files
        const reader = new FileReader();
        reader.onloadend = () => {
          setFilePreview(reader.result);
        };
        reader.readAsText(selectedFile);
      } else if (selectedFile.type.startsWith('video/')) {
        // Preview for video files
        setFilePreview(URL.createObjectURL(selectedFile));
      } else if (selectedFile.type === 'text/csv') {
        // Preview for CSV files
        const reader = new FileReader();
        reader.onloadend = () => {
          const csvText = reader.result;
          const rows = csvText.split('\n').map(row => row.split(','));
          setFilePreview(rows);  // Set preview as a 2D array (rows & columns)
        };
        reader.readAsText(selectedFile);
      } else if (selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        // Preview for XLSX files
        const reader = new FileReader();
        reader.onloadend = () => {
          const data = reader.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          const json = XLSX.utils.sheet_to_json(sheet, { header: 1 }); // Convert to 2D array
          setFilePreview(json);  // Set preview as a 2D array (rows & columns)
        };
        reader.readAsBinaryString(selectedFile);  // Read as binary string for XLSX
      } else {
        // No preview for other file types
        setFilePreview(null);
      }
    }
  };

  const uploadFile = async () => {
    if (!file) return;
    setUploading(true);
    setUploadError(null);
    setUploadSuccess(false);

    try {
      const blobClient = containerClient.getBlockBlobClient(file.name);

      // Upload the file to Azure Blob Storage
      const uploadBlobResponse = await blobClient.uploadData(file, {
        blobHTTPHeaders: { blobContentType: file.type },
      });

      console.log('Upload successful', uploadBlobResponse);
      setUploadSuccess(true);
    } catch (error) {
      console.error('Error uploading file', error);
      setUploadError(error.message || error.toString());
    } finally {
      setUploading(false);
    }
  };

  const renderFilePreview = () => {
    if (!filePreview) return <p>No preview available for this file type.</p>;

    if (Array.isArray(filePreview)) {
      // For CSV or XLSX (2D array) preview, render as a table
      return (
        <table>
          <thead>
            <tr>
              {filePreview[0].map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filePreview.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    }

    if (fileType && fileType.startsWith('image/')) {
      return <img src={filePreview} alt="File preview" style={{ maxWidth: '400px', maxHeight: '400px' }} />;
    }

    if (fileType === 'application/pdf') {
      return <embed src={filePreview} type="application/pdf" width="400px" height="400px" />;
    }

    if (fileType && fileType.startsWith('text/')) {
      return <pre>{filePreview}</pre>;
    }

    if (fileType && fileType.startsWith('video/')) {
      return (
        <video controls style={{ maxWidth: '400px', maxHeight: '400px' }}>
          <source src={filePreview} type={fileType} />
          Your browser does not support the video tag.
        </video>
      );
    }

    return <p>Unsupported file type for preview</p>;
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="upload-wrapper">

      {/* Modal for Preview */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={toggleModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>File Preview</h3>
            {renderFilePreview()}
            <button onClick={toggleModal}>Close</button>
          </div>
        </div>
      )}

      <div className="upload-container">
        <input type="file" onChange={handleFileChange} />
        <button onClick={uploadFile} disabled={uploading}>Upload</button>

        {uploading && <p>Uploading...</p>}
        {uploadSuccess && <p>File uploaded successfully!</p>}
        {uploadError && <p>Error: {uploadError}</p>}
      </div>
      <div className="right-upload">
        <h2>Upload the file to your Storage location</h2>

        {/* Clickable Text Area */}
        {file && (
          <div
            className="file-preview-clickable"
            onClick={toggleModal}
            style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
          >
            Click here to preview the file
          </div>
        )}
      </div>
    </div>
  );
};

//<div className="left-upload">
        //<p>Hello</p>
        //<h2>This is under construction</h2>

       // {/* Clickable Text Area */}
        //{file && (
         // <div
          //  className="file-preview-clickable"
          //  onClick={toggleModal}
           // style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
         // >
          //  Click here to preview the file
         // </div>
       // )}
      //</div>

export default FileUploadPage;