import React, { useState, useEffect } from 'react';

const FeedbackPage = () => {
    // State to control modal visibility
    const [showModal, setShowModal] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [userName, setUserName] = useState(''); // Added state for user's name
    const [submittedFeedback, setSubmittedFeedback] = useState([]);

    // Retrieve feedback from localStorage when the component mounts
    useEffect(() => {
        const savedFeedback = localStorage.getItem('submittedFeedback');
        if (savedFeedback) {
            setSubmittedFeedback(JSON.parse(savedFeedback));
        }
    }, []);

    // Update localStorage whenever submitted feedback changes
    useEffect(() => {
        if (submittedFeedback.length > 0) {
            localStorage.setItem('submittedFeedback', JSON.stringify(submittedFeedback));
        }
    }, [submittedFeedback]);

    // Handle modal toggle
    const toggleModal = () => {
        setShowModal(!showModal);
    };

    // Handle feedback input change
    const handleFeedbackChange = (event) => {
        setFeedback(event.target.value);
    };

    // Handle user name input change
    const handleNameChange = (event) => {
        setUserName(event.target.value);
    };

    // Handle feedback form submission
    const handleSubmit = () => {
        if (feedback.trim() && userName.trim()) {
            const timestamp = new Date().toLocaleString();
            const newFeedback = { text: feedback, timestamp, userName };

            // Add new feedback to the submittedFeedback array
            setSubmittedFeedback((prevFeedback) => [...prevFeedback, newFeedback]);
            setFeedback(''); // Clear feedback field
            setUserName(''); // Clear user name field
            toggleModal(); // Close modal after submission
        }
    };

    // Clear all submitted feedback
    const handleClearFeedback = () => {
        setSubmittedFeedback([]); // Reset the submitted feedback history
        localStorage.removeItem('submittedFeedback'); // Remove feedback from localStorage
    };

    return (
        <div style={sectionStyle}>
            <h1>Feedback</h1>
            <p>If you have any issues or feedback, please fill out the form below:</p>

            <button onClick={toggleModal} style={{ marginTop: '20px' }}>
                Submit Feedback
            </button>
            <button onClick={handleClearFeedback} style={{ marginTop: '20px', marginLeft: '10px' }}>
                Remove All Submitted Content
            </button>

            {/* Modal for feedback submission */}
            {showModal && (
                <div style={modalStyle}>
                    <div style={modalContentStyle}>
                        <h2>Submit Feedback</h2>
                        <input
                            type="text"
                            value={userName}
                            onChange={handleNameChange}
                            placeholder="Enter your name"
                            style={{ width: '80%', padding: '10px', marginBottom: '10px' }}
                        />
                        <textarea
                            value={feedback}
                            onChange={handleFeedbackChange}
                            placeholder="Enter your feedback here"
                            rows="4"
                            style={{ width: '80%', padding: '10px' }}
                        />
                        <div style={{ marginTop: '10px' }}>
                            <button onClick={handleSubmit}>Submit</button>
                            <button onClick={toggleModal} style={{ marginLeft: '10px' }}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Display submitted feedback with timestamps and user names {{ marginTop: '30px' }} */}
            
            <div style={{ marginTop: '30px' }}>
                <h3>Submitted Feedback</h3>
                {submittedFeedback.length > 0 ? (
                    <ul>
                        {submittedFeedback.map((feedbackItem, index) => (
                            <li key={index}>
                                <p><strong>{feedbackItem.timestamp}</strong> - <em>{feedbackItem.userName}</em></p>
                                <p>{feedbackItem.text}</p>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No feedback submitted yet.</p>
                )}
            </div>
        </div>
    );
};

// Modal styles
const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const modalContentStyle = {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    width: '300px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
};

// Transparent border style for the sections
const sectionStyle = {
    marginTop: '30px',
    padding: '20px',
    border: '3px solid rgba(0, 0, 0, 0.2)',  // Transparent black border
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',  // Optional light shadow for effect
};

export default FeedbackPage;